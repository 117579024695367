import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from "../components/header/header.component";
import { NavbarComponent } from "../components/navbar/navbar.component";
import { FooterComponent } from '../components/footer/footer.component';
import { CopyrightComponent } from '../components/copyright/copyright.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { ApiService } from '../services/api.service';
import { AccountService } from '../services/account.service';
import { FormsModule } from '@angular/forms';
import { Account } from '../models/account.model';
@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    HeaderComponent, 
    NavbarComponent,
    FooterComponent,
    CopyrightComponent,
    SidebarComponent,
    FormsModule
  ],
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {
  firstName: string = '';
  lastName: string = '';
  phone: string = '';
  birthday: string = '';
  gender: string = '';
  code: number = -1;

  constructor(private apiServ: ApiService, private accountServ: AccountService) {}

  ngOnInit(): void {
    this.accountServ.account$.subscribe(
      (account: (Account | null)) => {
        if(!account) {
          return;
        }
        this.firstName = account.first_name;
        this.lastName = account.last_name;
        this.phone = account.phone;
        this.birthday = account.birthday;
        this.gender = account.gender;
      }
    );
  }

  update() {
    this.apiServ.updateProfile(this.firstName, this.lastName, this.phone,this.birthday,this.gender)
    .subscribe({
      next: (account: any) => {
        this.accountServ.setAccount(account);
        this.code = 0;
      }
    });
  }
}
