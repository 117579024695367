import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import { WishlistService } from './services/wishlist.service';
import { ApiService } from './services/api.service';
import { CartService } from './services/cart.service';
import { AccountService } from './services/account.service';
import { routes } from './app.routes';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './token.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: WishlistService },
    { provide: ApiService },
    { provide: CartService },
    { provide: AccountService},
    provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes)]
};
