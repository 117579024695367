import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from "../components/header/header.component";
import { NavbarComponent } from "../components/navbar/navbar.component";
import { FooterComponent } from '../components/footer/footer.component';
import { CopyrightComponent } from '../components/copyright/copyright.component';
import { ApiService } from '../services/api.service';
@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    HeaderComponent, 
    NavbarComponent,
    FooterComponent,
    CopyrightComponent,
    RouterModule,
    CommonModule,
    FormsModule
  ],
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit{
  enabled: boolean = false;
  name: string = '';
  email: string = '';
  password: string = '';
  confirmPassword: string = '';
  registerFailed: boolean = false;
  
  constructor(private apiServ: ApiService, private router: Router) {}
  ngOnInit(): void {
    
  }
  register() {
    if(!this.enabled) {
      return;
    }
    this.apiServ.register(this.name, this.email, this.password).subscribe({
      next: (success) => {
        console.log('success=', success);
        this.registerFailed = !success;
        if(success) {
          this.router.navigate(['/activation']);
        }
      }
    });
  }
}
