import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from "../components/header/header.component";
import { NavbarComponent } from "../components/navbar/navbar.component";
import { FooterComponent } from '../components/footer/footer.component';
import { CopyrightComponent } from '../components/copyright/copyright.component';

@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [
    RouterModule,
    HeaderComponent, 
    NavbarComponent,
    FooterComponent,
    CopyrightComponent,
  ],
  templateUrl: './terms.component.html',
  styleUrl: './terms.component.scss'
})
export class TermsComponent {
  tab: number = 0;
  changeTab(tab: number) {
    this.tab = tab;
  }
}
