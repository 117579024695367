import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterModule } from '@angular/router';
import { ApiService } from '../services/api.service';
import { HeaderComponent } from "../components/header/header.component";
import { NavbarComponent } from "../components/navbar/navbar.component";
import { FooterComponent } from '../components/footer/footer.component';
import { CopyrightComponent } from '../components/copyright/copyright.component';

@Component({
  selector: 'app-activate',
  standalone: true,
  imports: [
    HeaderComponent,
    NavbarComponent,
    FooterComponent,
    CopyrightComponent,
    RouterModule
  ],
  templateUrl: './activate.component.html',
  styleUrl: './activate.component.scss'
})
export class ActivateComponent implements OnInit {
  success: boolean = true;
  constructor(private route: ActivatedRoute, private apiServ: ApiService) {}
  ngOnInit(): void {
    this.route.paramMap.subscribe( paramMap => {
      const email = paramMap.get('email');
      const code = paramMap.get('code');
      if(!email) {
        return;
      }
      if(!code) {
        return;
      }
      this.apiServ.activate(email, code).subscribe({
        next: (success: any) => {
          this.success = success;
        }
      });
    })
  }
}
