import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ImagePipe } from '../../pipes/image.pipe';
@Component({
  selector: 'app-copyright',
  standalone: true,
  imports: [ImagePipe],
  templateUrl: './copyright.component.html',
  styleUrl: './copyright.component.scss'
})
export class CopyrightComponent implements OnInit{
  cryptos: any;
  constructor(private apiServ: ApiService) {}
  ngOnInit(): void {
    this.apiServ.getCryptos().subscribe({
      next: (cryptos) => {
        this.cryptos = cryptos;
      }
    });
  }
}
