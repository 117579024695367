import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from "../components/header/header.component";
import { NavbarComponent } from "../components/navbar/navbar.component";
import { FooterComponent } from '../components/footer/footer.component';
import { CopyrightComponent } from '../components/copyright/copyright.component';
import { ProductCardComponent } from '../components/product-card/product-card.component';
import { ApiService } from '../services/api.service';
import { ImagePipe } from '../pipes/image.pipe';
@Component({
  selector: 'app-shop',
  standalone: true,
  imports: [
    HeaderComponent, 
    NavbarComponent,
    FooterComponent,
    CopyrightComponent,
    ProductCardComponent,
    ImagePipe,
    RouterModule
  ],
  templateUrl: './shop.component.html'
})
export class ShopComponent implements OnInit {
  categories: any;
  products: any;
  constructor(private apiServ: ApiService) {}
  ngOnInit(): void {
    this.apiServ.getCategoriesWithCount().subscribe(
      {
        next: (categories) => {
          this.categories = categories;
        }
      }
    );
    this.apiServ.getAllProductsWithMinMax().subscribe({
      next: (products) => {
        this.products = products;
      }
    });
  }
}
