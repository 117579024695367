import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { ImagePipe } from '../../pipes/image.pipe';
@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    RouterModule,
    ImagePipe
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit {
  categories: any;
  constructor(private apiServ: ApiService) {}
  ngOnInit(): void {
    this.apiServ.getCategories().subscribe({
      next: (categories) => {
        this.categories = categories;
      }
    });
  }
}
