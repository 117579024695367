import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from "../components/header/header.component";
import { NavbarComponent } from "../components/navbar/navbar.component";
import { FooterComponent } from '../components/footer/footer.component';
import { CopyrightComponent } from '../components/copyright/copyright.component';
import { CartService } from '../services/cart.service';
import { CartItem } from '../models/cart-item.model';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import {NgClass} from '@angular/common';
import { ImagePipe } from '../pipes/image.pipe';
import { FormsModule } from '@angular/forms';
@Component({
  selector: 'app-product',
  standalone: true,
  imports: [
    HeaderComponent, 
    NavbarComponent,
    FooterComponent,
    CopyrightComponent,
    NgClass,
    ImagePipe,
    FormsModule
  ],
  templateUrl: './product.component.html'
})
export class ProductComponent implements OnInit{
  product: any;
  selectedAmount: number = 0;
  _inputAmount: number = 0;
  isGift: boolean = false;

  to: string = '';
  email: string = '';
  repeatEmail: string = '';
  from: string = '';
  message: string = '';
  date: string = '';
  slug: string = '';

  public get inputAmount() {
    return this._inputAmount;
  }
  public set inputAmount(value) {
    this._inputAmount = value;
    this.selectedAmount = 0;
  }
  constructor(private cartService: CartService, private apiServ: ApiService, private route: ActivatedRoute ) {}

  addToCart(): void {
    const item: CartItem = {
      product_id: this.product.id,
      product_name: this.product.name,
      product_image: this.product.image,
      product_slug: this.slug,
      fiat_id: this.product.fiat_id,
      fiat_symbol: this.product.fiat_symbol,
      unit_price: this.product.unit_price,
      amount: this.selectedAmount ? this.selectedAmount : this.inputAmount,
      quantity: 1,
      to: this.to,
      email: this.email,
      from: this.from,
      message: this.message,
      delivery: Date.parse(this.date),
      min: this.product.min,
      max: this.product.max,
      availability: this.product.availability
    };
    this.cartService.addToCart(item);
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe( paramMap => {
      const slug = paramMap.get('slug');
      if(!slug) {
        return;
      }
      this.slug = slug;
      this.apiServ.getProductBySlug(slug).subscribe({
        next: (product: any) => {
          this.product = product;
          const amounts = product.amounts;
          this.selectedAmount = amounts[0];
        }
      });
    })
  }

  setIsGift(value: boolean) {
    this.isGift = value;
  }
  
  selectAmount(item: number) {
    this.selectedAmount = item;
    this._inputAmount = 0;
  }
}
