import { Component, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { HeaderComponent } from "../components/header/header.component";
import { NavbarComponent } from "../components/navbar/navbar.component";
import { FooterComponent } from '../components/footer/footer.component';
import { CopyrightComponent } from '../components/copyright/copyright.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { CartService } from '../services/cart.service';
import { ImagePipe } from '../pipes/image.pipe';
import { ApiService } from '../services/api.service';
import { TokenUtil } from '../wishlist/TokenUtil';

@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [
    HeaderComponent, 
    NavbarComponent,
    FooterComponent,
    CopyrightComponent,
    SidebarComponent,
    ImagePipe,
    RouterModule
  ],
  templateUrl: './cart.component.html'
})
export class CartComponent implements OnInit {
  cart: any;
  constructor(private cartServ: CartService, private apiServ: ApiService, private router: Router) {}
  ngOnInit(): void {
    this.cartServ.cart$.subscribe(
      cart => {
        this.cart = cart;
      }
    );
  }

  removeFromCart(productId: number) {
    this.cartServ.removeFromCart(productId);
  }

  checkout() {

    const token = localStorage.getItem('authToken');
    if (token && !TokenUtil.isTokenExpired(token)) {
      return this.apiServ.createOrder(this.cart).subscribe({
        next: (order: any) => {
          const code = order.code;
          this.cartServ.clearCart();
          this.router.navigate(['/checkout/' + code]);
        }
      });
    } else {
      // Redirect to the login page
      console.log('go here');
      return this.router.navigate(['/login'], { queryParams: { returnUrl: '/cart' } });
    }

  }
}
