<body>
    <app-header></app-header>
    <app-navbar></app-navbar>

    <!-- breadcrumb -->
    <div class="container py-4 flex items-center gap-3">
        <a href="../index.html" class="text-primary text-base">
            <i class="fa-solid fa-house"></i>
        </a>
        <span class="text-sm text-gray-400">
            <i class="fa-solid fa-chevron-right"></i>
        </span>
        <p class="text-gray-600 font-medium">Profile</p>
    </div>
    <!-- ./breadcrumb -->

    <!-- wrapper -->
    <div class="container grid grid-cols-12 items-start gap-6 pt-4 pb-16">

            <!-- sidebar -->
            <div class="col-span-3">
                <app-sidebar [page]="'profile'"></app-sidebar>
            </div>
            <!-- ./sidebar -->
    
            <!-- info -->
            <div class="col-span-9 shadow rounded px-6 pt-5 pb-7">
                <h4 class="text-lg font-medium capitalize mb-4">
                    Profile information
                </h4>
                <div class="space-y-4">
                    <div class="grid grid-cols-2 gap-4">
                        <div>
                            <label for="first">First name</label>
                            <input type="text" [(ngModel)]="firstName" name="first" id="first" class="input-box">
                        </div>
                        <div>
                            <label for="last">Last name</label>
                            <input type="text" [(ngModel)]="lastName"  name="last" id="last" class="input-box">
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                        <div>
                            <label for="birthday">Birthday</label>
                            <input type="date" [(ngModel)]="birthday"  name="birthday" id="birthday" class="input-box">
                        </div>
                        <div>
                            <label for="gender">Gender</label>
                            <select name="gender" [(ngModel)]="gender"  id="gender" class="input-box">
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                        <div>
                            <label for="phone">Phone number</label>
                            <input type="text" [(ngModel)]="phone"  name="phone" id="phone" class="input-box">
                        </div>
                    </div>
                </div>
    
                <div class="mt-4">
                    <button type="button" (click)="update()"
                        class="py-3 px-4 text-center text-white bg-primary border border-primary rounded-md hover:bg-transparent hover:text-primary transition font-medium">save
                        changes</button>
                </div>
                
                <div class="mt-4">
                    @if(code == 0) {
                        <span class="text-lime-500">Profile was updated successfully.</span>
                    }
                    @else if(code > 0) {
                        <span class="text-rose-500">Failed to Update profile.</span>
                    }
                </div>
            </div>
            <!-- ./info -->
    
    </div>

    <!-- ./wrapper -->
     
    <app-footer></app-footer>
    <app-copyright></app-copyright>

</body>