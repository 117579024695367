import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WishlistItem } from '../models/wishlist-item.model';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {
  wishlist = new BehaviorSubject<WishlistItem[]>([]);
  wishlist$ = this.wishlist.asObservable();

  constructor() {}

  addToWishlist(item: WishlistItem): void {
    const currentWishlist = this.wishlist.value;
    const existingItem = currentWishlist.find(p => 
        (p.product_id === item.product_id)
    );

    if (existingItem) {
        return;
    } else {
        currentWishlist.push(item);
    }

    this.wishlist.next([...currentWishlist]);
  }

  removeFromWishlist(productId: number): void {
    const updatedWishlist = this.wishlist.value.filter(item => item.product_id !== productId);
    this.wishlist.next([...updatedWishlist]);
  }

  clearWishlist(): void {
    this.wishlist.next([]);
  }
}
