import { Component, Input } from '@angular/core';
import { ImagePipe } from '../../pipes/image.pipe';
@Component({
  selector: 'app-token-image',
  standalone: true,
  imports: [
    ImagePipe
  ],
  templateUrl: './token-image.component.html',
  styleUrl: './token-image.component.scss'
})
export class TokenImageComponent {
  @Input() token: any;
}
