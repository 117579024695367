import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../services/api.service';
import { ImagePipe } from '../../pipes/image.pipe';
import { AccountService } from '../../services/account.service';
@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    ImagePipe
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit{
  account: any;
  @Input() page: string = '';
  constructor(private apiServ: ApiService, private accountServ: AccountService, private router: Router) {

  }

  ngOnInit(): void {
    this.apiServ.getMe().subscribe(
      {
        next: (account: any) => {
          this.account = account;
          this.accountServ.setAccount(account);
        }
      }
    );
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/']);
  }
}
