<div>
    <app-header></app-header>
    <app-navbar></app-navbar>

    <!-- login -->
    <div class="contain py-16">
        <div class="max-w-lg mx-auto px-6 py-7 rounded overflow-hidden">
            <h2 class="text-2xl uppercase font-medium mb-1">Activate your account</h2>
            @if(success) {
                <p class="text-gray-600 mb-6 text-sm">
                    Your account is activated
                </p>

                <p class="mt-4 text-center text-gray-600"><a [routerLink] = "['/login']"
                    class="text-primary">Login now</a></p>
            }
            @else {
                <p class="text-primary mb-6 text-sm">
                    Activation failed
                </p>
            }

        </div>
    </div>

    <app-footer></app-footer>
    <app-copyright></app-copyright>
</div>