export class TokenUtil {
        // Decode the JWT and check the expiration
        public static isTokenExpired(token: string): boolean {
            const payload = this.decodeToken(token);
        
            if (payload && payload.exp) {
              const expiryTime = payload.exp * 1000; // Convert to milliseconds
              const currentTime = Date.now();
        
              return expiryTime < currentTime;
            }
            
            return true; // If there's no exp field, consider the token expired
        }
        
          // Function to decode the JWT (manually)
        private static decodeToken(token: string): any {
            try {
              const payloadBase64 = token.split('.')[1]; // The payload is the second part of the token
              const decodedPayload = atob(payloadBase64); // Decode the base64 string
              return JSON.parse(decodedPayload); // Convert the decoded string to an object
            } catch (error) {
              console.error('Error decoding token', error);
              return null;
            }
        }
}