import { Routes } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AccountComponent } from './account/account.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ProductComponent } from './product/product.component';
import { ProfileComponent } from './profile/profile.component';
import { ShopComponent } from './shop/shop.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { CartComponent } from './cart/cart.component';
import { TermsComponent } from './terms/terms.component';
import { ActivationComponent } from './activation/activation.component';
import { ActivateComponent } from './activate/activate.component';
import { PasswordComponent } from './password/password.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderComponent } from './order/order.component';
import { VersionComponent } from './version/version.component';
import { AuthGuard } from './auth.guard';

export const routes: Routes = [
    {
        path: '', component: IndexComponent
    },
    {
        path: 'login', component: LoginComponent
    },
    {
        path: 'register', component: RegisterComponent
    },
    {
        path: 'account', component: AccountComponent, canActivate: [AuthGuard]
    },
    {
        path: 'password', component: PasswordComponent, canActivate: [AuthGuard]
    },
    {
        path: 'checkout/:code', component: CheckoutComponent, canActivate: [AuthGuard]
    },
    {
        path: 'orders', component: OrdersComponent, canActivate: [AuthGuard]
    },
    {
        path: 'order/:code', component: OrderComponent, canActivate: [AuthGuard]
    },
    {
        path: 'product/:slug', component: ProductComponent
    },
    {
        path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]
    },
    {
        path: 'category/:slug', component: ShopComponent
    },
    {
        path: 'shop', component: ShopComponent
    },
    {
        path: 'wishlist', component: WishlistComponent
    },
    {
        path: 'cart', component: CartComponent
    },
    {
        path: 'terms', component: TermsComponent
    },
    {
        path: 'version', component: VersionComponent
    },
    {
        path: 'activation', component: ActivationComponent
    },
    {
        path: 'activate/:email/:code', component: ActivateComponent
    }
    
];
