<div class="relative">
    <div>
        <img class="w-12" src="{{token.crypto.image | image}}">
    </div>
    @if(token.crypto.image != token.chain.image) {
        <div class="absolute right-0 bottom-0">
            <img class="w-5" src="{{token.chain.image | image}}">
        </div>
    }

</div>