import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CartItem } from '../models/cart-item.model';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private cart = new BehaviorSubject<CartItem[]>([]);
  cart$ = this.cart.asObservable();

  constructor() {}

  addToCart(item: CartItem): void {
    console.log('item to cart', item);
    const currentCart = this.cart.value;
    const existingItem = currentCart.find(p => 
        (p.product_id === item.product_id)
        &&
        (!p.to && !item.to)
    );

    if (existingItem) {
        existingItem.quantity += item.quantity;
    } else {
      currentCart.push(item);
    }

    this.cart.next([...currentCart]);
  }

  removeFromCart(productId: number): void {
    const updatedCart = this.cart.value.filter(item => item.product_id !== productId);
    this.cart.next([...updatedCart]);
  }

  clearCart(): void {
    this.cart.next([]);
  }
}
