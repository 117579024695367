<div>
    <app-header></app-header>
    <app-navbar></app-navbar>

    <!-- breadcrumb -->
    <div class="container py-4 flex items-center gap-3">
        <a [routerLink] = "['/']" class="text-primary text-base">
            <i class="fa-solid fa-house"></i>
        </a>
        <span class="text-sm text-gray-400">
            <i class="fa-solid fa-chevron-right"></i>
        </span>
        <p class="text-gray-600 font-medium">Terms & Privacy </p>
    </div>
    <!-- ./breadcrumb -->

    <!-- wrapper -->
    <div class="container pt-4 pb-16">
        <ul class="hidden text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
            <li class="w-full focus-within:z-10" (click)="changeTab(0)">
                <a href="javascript:void" class="inline-block w-full p-4 text-gray-900 bg-gray-100 border-r border-gray-200 dark:border-gray-700 rounded-s-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none dark:bg-gray-700 dark:text-white" aria-current="page">terms</a>
            </li>
            <li class="w-full focus-within:z-10" (click)="changeTab(1)">
                <a href="javascript:void" class="inline-block w-full p-4 bg-white border-r border-gray-200 dark:border-gray-700 hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700">privacy</a>
            </li>
        </ul>
        <div class="m-3">
            @if(tab == 0) {
                <div>
                    Terms and conditions
                </div>
            } 
            @else {
                <div>
                    Privacy Policy
                </div> 
            }
        </div>


    </div>

    <app-footer></app-footer>
    <app-copyright></app-copyright>

</div>