import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from "../components/header/header.component";
import { NavbarComponent } from "../components/navbar/navbar.component";
import { FooterComponent } from '../components/footer/footer.component';
import { CopyrightComponent } from '../components/copyright/copyright.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { WishlistService } from '../services/wishlist.service';
import { ImagePipe } from '../pipes/image.pipe';
@Component({
  selector: 'app-wishlist',
  standalone: true,
  imports: [
    HeaderComponent, 
    NavbarComponent,
    FooterComponent,
    CopyrightComponent,
    SidebarComponent,
    ImagePipe,
    RouterModule
  ],
  templateUrl: './wishlist.component.html'
})
export class WishlistComponent implements OnInit {
  wishlist: any;
  constructor(private wishlistServ: WishlistService) {}
  ngOnInit(): void {
    this.wishlistServ.wishlist$.subscribe(
      wishlist => {
        this.wishlist = wishlist;
      }
    );
  }

  removeFromWishlist(productId: number) {
    this.wishlistServ.removeFromWishlist(productId);
  }

}
