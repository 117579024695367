import { Component } from '@angular/core';
import { version } from '../../environments/version';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-version',
  standalone: true,
  imports: [],
  templateUrl: './version.component.html',
  styleUrl: './version.component.scss'
})
export class VersionComponent {
  version = version;
  environment = environment;
}
