<div>
    <app-header></app-header>
    <app-navbar></app-navbar>

    <!-- login -->
    <div class="contain py-16">
        <div class="max-w-lg mx-auto shadow px-6 py-7 rounded overflow-hidden">
            <h2 class="text-2xl uppercase font-medium mb-1">Create an account</h2>
            <p class="text-gray-600 mb-6 text-sm">
                Register for new cosutumer
            </p>
                <div class="space-y-2">
                    <div>
                        <label for="name" class="text-gray-600 mb-2 block">Full Name</label>
                        <input type="text" name="name" id="name" [(ngModel)]="name"
                            class="block w-full border border-gray-300 px-4 py-3 text-gray-600 text-sm rounded focus:ring-0 focus:border-primary placeholder-gray-400"
                            placeholder="Enter your full name">
                    </div>
                    <div>
                        <label for="email" class="text-gray-600 mb-2 block">Email address</label>
                        <input type="email" name="email" id="email" [(ngModel)]="email"
                            class="block w-full border border-gray-300 px-4 py-3 text-gray-600 text-sm rounded focus:ring-0 focus:border-primary placeholder-gray-400"
                            placeholder="Enter your email address">
                    </div>
                    <div>
                        <label for="password" class="text-gray-600 mb-2 block">Password</label>
                        <input type="password" name="password" id="password" [(ngModel)]="password"
                            class="block w-full border border-gray-300 px-4 py-3 text-gray-600 text-sm rounded focus:ring-0 focus:border-primary placeholder-gray-400"
                            placeholder="*******">
                    </div>
                    <div>
                        <label for="confirm" class="text-gray-600 mb-2 block">Confirm password</label>
                        <input type="password" name="confirm" id="confirm" [(ngModel)]="confirmPassword"
                            class="block w-full border border-gray-300 px-4 py-3 text-gray-600 text-sm rounded focus:ring-0 focus:border-primary placeholder-gray-400"
                            placeholder="*******">
                    </div>
                </div>
                @if(password && confirmPassword && password !== confirmPassword) {
                    <div class="mt-3 text-primary">
                        password and confirm password not match.
                    </div>
                }
                @if(registerFailed) {
                    <div class="mt-3 text-primary">
                        Register failed.
                    </div>
                }

                <div class="mt-6">
                    <div class="flex items-center">
                        <input type="checkbox" name="aggrement" id="aggrement"
                            class="text-primary focus:ring-0 rounded-sm cursor-pointer" [(ngModel)]="enabled">
                        <label for="aggrement" class="text-gray-600 ml-3 cursor-pointer">I have read and agree to the <a
                            [routerLink] = "['/terms']" class="text-primary">terms & conditions</a></label>
                    </div>
                </div>


                <div class="mt-4">
                    <button type="button" (click)="register()"
                        class=" block w-full py-2 text-center text-white bg-primary border border-primary rounded  transition uppercase font-roboto font-medium"
                        [ngClass]="(enabled && password && (password == confirmPassword)) ? 'hover:bg-transparent hover:text-primary' : 'cursor-not-allowed bg-red-400 border border-red-400'">create
                        account</button>
                </div>

            <!-- login with -->

            <!--
            <div class="mt-6 flex justify-center relative">
                <div class="text-gray-600 uppercase px-3 bg-white z-10 relative">Or signup with</div>
                <div class="absolute left-0 top-3 w-full border-b-2 border-gray-200"></div>
            </div>
            <div class="mt-4 flex gap-4">
                <a href="#"
                    class="w-1/2 py-2 text-center text-white bg-blue-800 rounded uppercase font-roboto font-medium text-sm hover:bg-blue-700">facebook</a>
                <a href="#"
                    class="w-1/2 py-2 text-center text-white bg-red-600 rounded uppercase font-roboto font-medium text-sm hover:bg-red-500">google</a>
            </div>
            -->
            <!-- ./login with -->

            <p class="mt-4 text-center text-gray-600">Already have account? <a [routerLink] = "['/login']"
                    class="text-primary">Login now</a></p>
        </div>
    </div>
    <!-- ./login -->

    <app-footer></app-footer>
    <app-copyright></app-copyright>

</div>