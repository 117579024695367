import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const baseUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  createOrder(orderItems: any) {
    //const token = this.authServ.getToken();
    const obs = new Observable(
      (obs) => {
        const data = {
          items: orderItems
        }

        this.httpClient.post(`${baseUrl}auth/orders`, data).subscribe(
          (data: any)=>{
            if(!data.code) {
              obs.next(data.data); 
            }
            
      })});

    return obs;
  }
  getOrders() {
    const obs = new Observable(
      (obs) => {

        this.httpClient.get(`${baseUrl}auth/orders`).subscribe(
          (data: any)=>{
            if(!data.code) {
              obs.next(data.data); 
            }
            
      })});

    return obs;
  }
  getMe() {
    const obs = new Observable(
      (obs) => {
        this.httpClient.get(`${baseUrl}auth/me`).subscribe(
          (data: any)=>{
            if(!data.code) {
              obs.next(data.data); 
            }
      })});

    return obs;
  }

  updatePassword(oldPassword: string, password: string) {
    const obs = new Observable(
      (obs) => {
        const data = {
          old_password: oldPassword,
          password
        };
        this.httpClient.post(`${baseUrl}auth/password`, data).subscribe(
          (data: any)=>{
            if(!data.code) {
              obs.next(data.data); 
            }
      })});

    return obs;
  }

  updateProfile(firstName: string, lastName: string, phone: string, birthday: string, gender: string) {
    const obs = new Observable(
      (obs) => {
        const data = {
          first_name: firstName,
          last_name: lastName,
          phone: phone,
          birthday: birthday,
          gender: gender
        };
        this.httpClient.post(`${baseUrl}auth/profile`, data).subscribe(
          (data: any)=>{
            if(!data.code) {
              obs.next(data.data); 
            }
      })});

    return obs;
  }

  getOrderByCode(code: string) {
    const obs = new Observable(
      (obs) => {
        this.httpClient.get(`${baseUrl}auth/orders/code/` + code).subscribe(
          (data: any)=>{
            if(!data.code) {
              obs.next(data.data); 
            }
      })});

    return obs;
  }

  getOrderWithQuote(code: string) {
    const obs = new Observable(
      (obs) => {
        this.httpClient.get(`${baseUrl}auth/orders/with_quote/` + code).subscribe(
          (data: any)=>{
            if(!data.code) {
              obs.next(data.data); 
            }
      })});

    return obs;
  }

  createInvoice(code: string, tokenId: number) {
    const obs = new Observable(
      (obs) => {
        const data = {
          code,
          token_id: tokenId,
        }
        this.httpClient.post(`${baseUrl}auth/orders/invoice`, data).subscribe(
          (data: any)=>{
            obs.next(data.data); 
      })});

    return obs;
  }

  createInvoiceForService(code: string, cryptoId: number, serviceId: number) {
    const obs = new Observable(
      (obs) => {
        const data = {
          code,
          crypto_id: cryptoId,
          service_id: serviceId
        }
        this.httpClient.post(`${baseUrl}auth/orders/invoice_for_service`, data).subscribe(
          (data: any)=>{
            obs.next(data.data); 
      })});

    return obs;
  }

  activate(email: string, code: string) {
    const obs = new Observable(
      (obs) => {
        const data = {
          email,
          code
        }
        this.httpClient.post(`${baseUrl}auth/activate`, data).subscribe(
          (data: any)=>{
            let success = true;
            if(data.code) {
              success = false;
            }
            obs.next(success); 
      })});

    return obs;
  }

  register(name: string, email: string, password: string) {
    const obs = new Observable(
      (obs) => {
        const data = {
          name,
          email,
          password
        }
        this.httpClient.post(`${baseUrl}auth/register`, data).subscribe(
          (data: any)=>{
            let success = true;
            if(data.code) {
              success = false;
            }
            obs.next(success); 
      })});

    return obs;
  }

  login(email: string, password: string) {
    const obs = new Observable(
      (obs) => {
        const data = {
          email,
          password
        }
        this.httpClient.post(`${baseUrl}auth/login`, data).subscribe(
          (data: any)=>{
            let token = '';
            console.log('data herr=', data);
            if(!data.code) {
              token = data.data.access_token;
            }
            obs.next(token); 
      })});

    return obs;
  }

  getCategories() {

    const obs = new Observable(
      (obs) => {
        this.httpClient.get(`${baseUrl}categories`).subscribe(
          (data: any)=>{
            obs.next(data.data); 
      })});

    return obs;

  }

  getCryptos() {

    const obs = new Observable(
      (obs) => {
        this.httpClient.get(`${baseUrl}cryptos`).subscribe(
          (data: any)=>{
            obs.next(data.data); 
      })});

    return obs;

  }

  getProductBySlug(slug: string) {
    const obs = new Observable(
      (obs) => {
        this.httpClient.get(`${baseUrl}products/slug/${slug}`).subscribe(
          (data: any)=>{
            obs.next(data.data); 
      })});

    return obs;
  }


  getCategoriesWithCount() {
    const obs = new Observable(
      (obs) => {
        this.httpClient.get(`${baseUrl}categories/with_count`).subscribe(
          (data: any)=>{
            obs.next(data.data); 
      })});

    return obs;
  }

  getAllProductsWithMinMax() {
    const obs = new Observable(
      (obs) => {
        this.httpClient.get(`${baseUrl}products/with_min_max`).subscribe(
          (data: any)=>{
            obs.next(data.data); 
      })});

    return obs;
  }

  getPayments(cryptoId: number) {
    const obs = new Observable(
      (obs) => {
        this.httpClient.get(`${baseUrl}payments/${cryptoId}`).subscribe(
          (data: any)=>{
            obs.next(data.data); 
      })});

    return obs;
  }
}
