import { Component, Input } from '@angular/core';
import { WishlistItem } from '../../models/wishlist-item.model';
import { WishlistService } from '../../services/wishlist.service';
import { ImagePipe } from '../../pipes/image.pipe';
@Component({
  selector: 'app-product-card',
  standalone: true,
  imports: [
    ImagePipe
  ],
  providers: [],
  templateUrl: './product-card.component.html',
  styleUrl: './product-card.component.scss'
})
export class ProductCardComponent {
  @Input() product: any;

  constructor(private wishlistService: WishlistService ) {}
  addToWishlist() {
    const item: WishlistItem = {
      product_id: this.product.id,
      product_name: this.product.name,
      product_image: this.product.image,
      product_slug: this.product.slug,
      availability: this.product.availability,
      min: this.product.min,
      max: this.product.max
    };
    this.wishlistService.addToWishlist(item);
  }
}
