import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderComponent } from "../components/header/header.component";
import { NavbarComponent } from "../components/navbar/navbar.component";
import { FooterComponent } from '../components/footer/footer.component';
import { CopyrightComponent } from '../components/copyright/copyright.component';
import { ApiService } from '../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { ImagePipe } from '../pipes/image.pipe';
import { NumberPipe } from '../pipes/number.pipe';
import { TokenImageComponent } from '../components/token-image/token-image.component';
import { QRCodeModule } from 'angularx-qrcode';
import { CountdownTimerComponent } from '../components/countdown-timer/countdown-timer.component';
import { webSocket } from 'rxjs/webSocket';
import * as exaddr from '../lib/exaddr';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [
    HeaderComponent, 
    NavbarComponent,
    FooterComponent,
    CopyrightComponent,
    ImagePipe,
    NumberPipe,
    TokenImageComponent,
    QRCodeModule,
    CountdownTimerComponent
  ],
  templateUrl: './checkout.component.html'
})
export class CheckoutComponent implements OnInit, OnDestroy {
  order: any;
  code: string = '';
  items: any;
  totalAmount: number = 0;
  fiatSymbol: string = '';
  quote: any;
  tokens: any;
  services: any;
  tab: string = '';
  invoice: any;
  cryptoId: number = 0;
  cryptoAmount: number = 0;
  cryptoSymbol: string = '';
  expiredInSeconds: number = 60*30;
  tokenId: number = 0;
  address: string = '';
  private socket$: any;

  constructor(private apiServ: ApiService, private route: ActivatedRoute) {}
  ngOnInit(): void {
    //this.tab = 'expired';
    this.route.paramMap.subscribe( paramMap => {
      const code = paramMap.get('code') ?? '';
      this.code = code;
      this.getQuote();
    });
    
    this.initWebSocket();


    //
  }

  initWebSocket() {
    const url = environment.wsUrl + 'app/d9plp39a6g1au35thvz6?protocol=7&client=js&version=8.4.0-rc2&flash=false';
    this.socket$ = webSocket(url);


    //const data = 
    this.socket$.subscribe(
      (message: any) => {
        console.log('Message from server: ', message);
        const event = message.event;
        if(event == 'pusher:connection_established') {
          const res = {
            event:"pusher:subscribe",
            data:{
              auth:"",
              channel:"orders"
            }
          };
          this.sendMessage(res);
        } else
        if(event == 'App\\Events\\OrderPaid') {
          console.log('got event');
          const data = JSON.parse(message.data);
          console.log('data===', data);
          const order = data.order;
          if(order.code == this.code && order.payment_status == 1) {
            this.tab = 'paid';
          }
        }
        /*
        data
: 
"{\"socket_id\":\"407647945.327512391\",\"activity_timeout\":30}"
event
: 
"pusher:connection_established"
        */
      },
      (error: any) => {
        console.error('WebSocket error: ', error);
      },
      () => {
        console.log('WebSocket connection closed.');
      }
    );
  }

  getQuote() {
    this.tab = 'quote';
    this.apiServ.getOrderWithQuote(this.code).subscribe({
      next: (order: any) => {
        this.order = order;
        this.fiatSymbol = order.fiat_symbol;
        this.items = order.items;
        this.totalAmount = order.total_amount;
        this.quote = order.quote;
        
        if(order.payment_status == 1) {
          this.tab = 'paid';
        }
      }
    });
  }

  copyText(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Text copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  }
  
  refresh() {
    this.apiServ.createInvoice(this.code, this.tokenId).subscribe(
      {
        next: (data: any) => {
          this.tab = 'invoice';
          this.invoice = data.invoice;
          this.expiredInSeconds = data.expired_in_seconds;
          if(this.expiredInSeconds < 0) {
            this.isExpired();
          }
        }
      }
    );
  }

  selectCrypto(crypto: any) {
    console.log('crypto====', crypto);
    const cryptoId = crypto.crypto_id;
    this.cryptoId = cryptoId;
    this.cryptoAmount = crypto.crypto_amount;
    this.cryptoSymbol = crypto.crypto_symbol;
    this.apiServ.getPayments(cryptoId).subscribe(
      {
        next: (payments: any) => {
          this.tab = 'payments';
          this.tokens = payments.tokens;
          this.services = payments.services;
        }
      }
    );
  }

  isExpired() {
    this.tab = 'expired';
  }

  selectService(service: any) {
    const serviceId = service.id;
    this.apiServ.createInvoiceForService(this.code, this.cryptoId, serviceId).subscribe(
      {
        next: (data: any) => {
          this.tab = 'invoice';
          this.invoice = data.invoice;
          this.address = 'i=' + this.invoice.code;
          console.log('this.address===', this.address);
          this.expiredInSeconds = data.expired_in_seconds;
          if(this.expiredInSeconds < 0) {
            this.isExpired();
          }

        }
      }
    );
  }
  
  selectToken(token: any) {
    const tokenId = token.id;
    this.tokenId = tokenId;
    this.apiServ.createInvoice(this.code, tokenId).subscribe(
      {
        next: (data: any) => {
          this.tab = 'invoice';
          this.invoice = data.invoice;
          this.address = this.invoice.wallet.address;
          
          this.expiredInSeconds = data.expired_in_seconds;
          if(this.expiredInSeconds < 0) {
            this.isExpired();
          }

          const chainCode = this.invoice.token.chain.code;
          if(chainCode == 'FAB') {
            this.address = exaddr.toKbpayAddress(this.address);
          }
        }
      }
    );
  }

  sendMessage(message: any): void {
    this.socket$.next(message);
  }

  disconnect(): void {
    this.socket$.complete();
  }

  ngOnDestroy(): void {
    this.disconnect();
  }
}
