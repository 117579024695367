import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from "../components/header/header.component";
import { NavbarComponent } from "../components/navbar/navbar.component";
import { FooterComponent } from '../components/footer/footer.component';
import { CopyrightComponent } from '../components/copyright/copyright.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { ApiService } from '../services/api.service';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { PaymentStatusComponent } from '../components/payment-status/payment-status.component';
import { ImagePipe } from '../pipes/image.pipe';
@Component({
  selector: 'app-order',
  standalone: true,
  imports: [
    HeaderComponent, 
    NavbarComponent,
    FooterComponent,
    CopyrightComponent,
    SidebarComponent,
    PaymentStatusComponent,
    CommonModule,
    RouterModule,
    ImagePipe
  ],
  templateUrl: './order.component.html',
  styleUrl: './order.component.scss'
})
export class OrderComponent implements OnInit{
  order: any;
  code: string = '';
  constructor(private apiServ: ApiService, private route: ActivatedRoute) {}
  ngOnInit(): void {
    this.route.paramMap.subscribe( paramMap => {
      const code = paramMap.get('code') ?? '';
      this.code = code;
      this.apiServ.getOrderByCode(code).subscribe({
        next: (order: any) => {
          this.order = order;
        }
      });
    });
  }
}
