import {Pipe, PipeTransform} from '@angular/core';
import { environment } from '../../environments/environment';
const baseUrl = environment.apiUrl;

@Pipe({  standalone: true,  name: 'image',})
export class ImagePipe implements PipeTransform {
  transform(value: string): string {
    return baseUrl + value;
  }
}
