import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from "../components/header/header.component";
import { NavbarComponent } from "../components/navbar/navbar.component";
import { FooterComponent } from '../components/footer/footer.component';
import { CopyrightComponent } from '../components/copyright/copyright.component';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    HeaderComponent, 
    NavbarComponent,
    FooterComponent,
    CopyrightComponent,
    RouterModule,
    FormsModule
  ],
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  email: string = '';
  password: string = '';
  loginFailed: boolean = false;
  returnUrl: string = '/account';
  constructor(
    private apiServ: ApiService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

  }

  signInWithFB(): void {
  }

  login() {

    this.apiServ.login(this.email, this.password).subscribe({
      next: (token: any) => {
        console.log('token=', token);
        this.loginFailed = !token;
        if(token) {
          localStorage.setItem('authToken', token);
          this.router.navigate([this.returnUrl]);
        }
      }
    });
  }
}
