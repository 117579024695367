<footer class="bg-white pt-16 pb-12 border-t border-gray-100">
    <div class="container grid grid-cols-1 ">
        <div class="col-span-1 space-y-4">
            <span class="text-3xl"><span class="text-rose-500">BI</span>AMM</span>
            <!--
            <img src="assets/images/logo.svg" alt="logo" class="w-30">
            -->
            <div class="mr-2">
                <p class="text-gray-500">
                    Join our community
                </p>
            </div>
            <div class="flex space-x-5">
                <a href="#" class="text-gray-400 hover:text-gray-500"><i
                        class="fa-brands fa-facebook-square"></i></a>
                <a href="#" class="text-gray-400 hover:text-gray-500"><i
                        class="fa-brands fa-instagram-square"></i></a>
                <a href="#" class="text-gray-400 hover:text-gray-500"><i
                        class="fa-brands fa-twitter-square"></i></a>
                <a href="#" class="text-gray-400 hover:text-gray-500">
                    <i class="fa-brands fa-github-square"></i>
                </a>
            </div>
        </div>

        <div class="col-span-2 grid grid-cols-2 gap-4 mt-8">
            <div class="grid grid-cols-2 gap-4 md:gap-8">
                <div>
                    <h3 class="text-sm font-semibold text-gray-400 uppercase tracking-wider">Categories</h3>
                    <div class="mt-4 space-y-4">

                        @for (item of categories; track item) {
                            <a href="/category/{{item.slug}}" class="text-base text-gray-500 hover:text-gray-900 block">{{item.name}}</a>
                        }
                    </div>
                </div>

                <div>
                    <h3 class="text-sm font-semibold text-gray-400 uppercase tracking-wider">Help</h3>
                    <div class="mt-4 space-y-4">
                        <a href="#" class="text-base text-gray-500 hover:text-gray-900 block">Support</a>
                        <a href="#" class="text-base text-gray-500 hover:text-gray-900 block">Supported Crypto</a>
                        <a href="#" class="text-base text-gray-500 hover:text-gray-900 block">How it Works</a>
                        <a href="#" class="text-base text-gray-500 hover:text-gray-900 block">Newsletter</a>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-2 gap-8">
                <div>
                    <h3 class="text-sm font-semibold text-gray-400 uppercase tracking-wider">About Us</h3>
                    <div class="mt-4 space-y-4">
                        <a href="#" class="text-base text-gray-500 hover:text-gray-900 block">Our history</a>
                        <a href="#" class="text-base text-gray-500 hover:text-gray-900 block">Known from</a>
                        <a href="#" class="text-base text-gray-500 hover:text-gray-900 block">Press Resources</a>
                        <a href="#" class="text-base text-gray-500 hover:text-gray-900 block">Blogs</a>
                    </div>
                </div>

                <div>
                    <h3 class="text-sm font-semibold text-gray-400 uppercase tracking-wider">Legal</h3>
                    <div class="mt-4 space-y-4">
                        <a href="#" class="text-base text-gray-500 hover:text-gray-900 block">Terms and Conditions</a>
                        <a href="#" class="text-base text-gray-500 hover:text-gray-900 block">Privacy Policy</a>
                        <!--
                        <a href="#" class="text-base text-gray-500 hover:text-gray-900 block">KYC & AML</a>
                        -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>