import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from "../components/header/header.component";
import { NavbarComponent } from "../components/navbar/navbar.component";
import { FooterComponent } from '../components/footer/footer.component';
import { CopyrightComponent } from '../components/copyright/copyright.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { ApiService } from '../services/api.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PaymentStatusComponent } from '../components/payment-status/payment-status.component';
@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [
    HeaderComponent, 
    NavbarComponent,
    FooterComponent,
    CopyrightComponent,
    SidebarComponent,
    PaymentStatusComponent,
    CommonModule,
    RouterModule
  ],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.scss'
})
export class OrdersComponent implements OnInit {
  orders: any;
  constructor(private apiServ: ApiService) {}

  ngOnInit(): void {
    this.apiServ.getOrders().subscribe({
      next: (orders: any) => {
        this.orders = orders;
      }
    });
  }
}
