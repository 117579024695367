<body>
    <app-header></app-header>
    <app-navbar></app-navbar>

    <!-- breadcrumb -->
    <div class="container py-4 flex items-center gap-3">
        <a href="../index.html" class="text-primary text-base">
            <i class="fa-solid fa-house"></i>
        </a>
        <span class="text-sm text-gray-400">
            <i class="fa-solid fa-chevron-right"></i>
        </span>
        <p class="text-gray-600 font-medium">My Order History</p>
    </div>
    <!-- ./breadcrumb -->

    <!-- wrapper -->
    <div class="container grid grid-cols-12 items-start gap-6 pt-4 pb-16">

            <!-- sidebar -->
            <div class="col-span-3">
                <app-sidebar [page]="'orders'"></app-sidebar>
            </div>
            <!-- ./sidebar -->
    
            <!-- info -->
            <div class="col-span-9 shadow rounded px-6 pt-5 pb-7">
                <h4 class="text-lg font-medium capitalize mb-4">
                    My Order history
                </h4>

                <table class="w-full border-collapse bg-white text-left text-sm text-gray-500">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-6 py-4 font-medium text-gray-900">Code</th>
                        <th scope="col" class="px-6 py-4 font-medium text-gray-900">Date</th>
                        <th scope="col" class="px-6 py-4 font-medium text-gray-900">Total Amount</th>
                        <th scope="col" class="px-6 py-4 font-medium text-gray-900">Payment Status</th>
                        <th scope="col" class="px-6 py-4 font-medium text-gray-900"></th>
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-100 border-t border-gray-100">
                      @for (item of orders; track item) {
                      <tr class="odd:bg-white even:bg-gray-50">
                        <th class="px-6 py-4 font-medium text-gray-900">{{item.code}}</th>
                        <td class="px-6 py-4">{{item.created_at | date}}</td>
                        <td class="px-6 py-4">{{item.fiat.symbol}}{{item.total_amount}}</td>
                        <td class="px-6 py-4">
                         <app-payment-status [status]="item.payment_status"></app-payment-status>
                        </td>
                        <td class="flex justify-end gap-4 px-6 py-4 font-medium">
                          <a href="">Delete</a>
                          <a [routerLink] = "['/order/' + item.code]" class="text-primary-700">Detail</a>
                        </td>
                      </tr>
                      }
                    </tbody>
                  </table>

            </div>
            <!-- ./info -->
    
    </div>

    <!-- ./wrapper -->
     
    <app-footer></app-footer>
    <app-copyright></app-copyright>

</body>