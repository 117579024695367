<body>
    <app-header></app-header>
    <app-navbar></app-navbar>

    <!-- breadcrumb -->
    <div class="container py-4 flex items-center gap-3">
        <a href="../index.html" class="text-primary text-base">
            <i class="fa-solid fa-house"></i>
        </a>
        <span class="text-sm text-gray-400">
            <i class="fa-solid fa-chevron-right"></i>
        </span>
        <p class="text-gray-600 font-medium">Change Passsword</p>
    </div>
    <!-- ./breadcrumb -->

    <!-- wrapper -->
    <div class="container grid grid-cols-12 items-start gap-6 pt-4 pb-16">

            <!-- sidebar -->
            <div class="col-span-3">
                <app-sidebar [page]="'password'"></app-sidebar>
            </div>
            <!-- ./sidebar -->
    
            <!-- info -->
            <div class="col-span-9 shadow rounded px-6 pt-5 pb-7">
                <h4 class="text-lg font-medium capitalize mb-4">
                    Change Password
                </h4>
                <div class="space-y-4">
                    <div>
                        <label for="password" class="text-gray-600 mb-2 block">Old Password</label>
                        <input type="password" name="password" id="old_password" [(ngModel)]="oldPassword"
                            class="block w-full border border-gray-300 px-4 py-3 text-gray-600 text-sm rounded focus:ring-0 focus:border-primary placeholder-gray-400"
                            placeholder="*******">
                    </div>
                    <div>
                        <label for="password" class="text-gray-600 mb-2 block">Password</label>
                        <input type="password" name="password" id="password" [(ngModel)]="password"
                            class="block w-full border border-gray-300 px-4 py-3 text-gray-600 text-sm rounded focus:ring-0 focus:border-primary placeholder-gray-400"
                            placeholder="*******">
                    </div>
                    <div>
                        <label for="confirm" class="text-gray-600 mb-2 block">Confirm password</label>
                        <input type="password" name="confirm" id="confirm_password" [(ngModel)]="confirmPassword"
                            class="block w-full border border-gray-300 px-4 py-3 text-gray-600 text-sm rounded focus:ring-0 focus:border-primary placeholder-gray-400"
                            placeholder="*******">
                    </div>
                </div>
    
                <div class="mt-4">
                    <button type="button" (click)="update()"
                        class="py-3 px-4 text-center text-white bg-primary border border-primary rounded-md hover:bg-transparent hover:text-primary transition font-medium">save
                        changes</button>
                </div>

                <div class="mt-4">
                    @if(code == 0) {
                        <span class="text-lime-500">Password was updated successfully.</span>
                    }
                    @else if(code > 0) {
                        <span class="text-rose-500">Failed to Update password.</span>
                    }
                </div>

            </div>
            <!-- ./info -->
    
    </div>

    <!-- ./wrapper -->
     
    <app-footer></app-footer>
    <app-copyright></app-copyright>

</body>