import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from "../components/header/header.component";
import { NavbarComponent } from "../components/navbar/navbar.component";
import { FooterComponent } from '../components/footer/footer.component';
import { CopyrightComponent } from '../components/copyright/copyright.component';
import { ProductCardComponent } from '../components/product-card/product-card.component';
import { ApiService } from '../services/api.service';
import { ImagePipe } from '../pipes/image.pipe';
@Component({
  selector: 'app-index',
  standalone: true,
  imports: [
    HeaderComponent, 
    NavbarComponent,
    FooterComponent,
    CopyrightComponent,
    ProductCardComponent,
    ImagePipe
  ],
  providers: [],
  templateUrl: './index.component.html'
})
export class IndexComponent implements OnInit{
  categories: any;
  products: any;
  constructor(private apiServ: ApiService) {

  }

  ngOnInit(): void {
    this.getCategories();
    this.getProducts();
  }

  getCategories() {
    this.apiServ.getCategories().subscribe(
      (categories: any) => {
        this.categories = categories;
      }
    );
  }

  getProducts() {
    this.apiServ.getAllProductsWithMinMax().subscribe(
      (products: any) => {
        this.products = products;
      }
    );
  }

}
