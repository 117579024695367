import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-countdown-timer',
  standalone: true,
  templateUrl: './countdown-timer.component.html'
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  @Input() countdownTime!: number; // Input to take countdown time in seconds

  @Output() onExpired = new EventEmitter();
  private subscription!: Subscription;
  remainingTime!: number;
  minutes!: number;
  seconds!: number;

  ngOnInit() {
    this.startCountdown();
    this.showTimer();
  }

  showTimer() {
    if (this.remainingTime > 0) {
      this.remainingTime--;
      this.minutes = Math.floor(this.remainingTime / 60);
      this.seconds = this.remainingTime % 60;
    } else {
      this.subscription.unsubscribe();
      this.onExpired.emit();
    }
  }
  startCountdown() {
    this.remainingTime = this.countdownTime;

    this.subscription = interval(1000).subscribe(() => {
      this.showTimer();
    });
  }

  padWithZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
