import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { TokenUtil } from './wishlist/TokenUtil';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    const token = localStorage.getItem('authToken');
    if (token && !TokenUtil.isTokenExpired(token)) {
      return true;
    } else {
      // Redirect to the login page
      return this.router.createUrlTree(['/login'], { queryParams: { returnUrl: state.url } });
    }
  }


}
