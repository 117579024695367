<div>
    <app-header></app-header>
    <app-navbar></app-navbar>

    <!-- login -->
    <div class="contain py-16">
        <div class="max-w-lg mx-auto px-6 py-7 rounded overflow-hidden">
            <h2 class="text-2xl uppercase font-medium mb-1">Activate your account</h2>
            <p class="text-gray-600 mb-6 text-sm">
                Please click the link in your email to activate your account.
            </p>
        </div>
    </div>

    <app-footer></app-footer>
    <app-copyright></app-copyright>
</div>