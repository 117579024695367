import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Account } from '../models/account.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  account = new BehaviorSubject<Account | null>(null);
  account$ = this.account.asObservable();

  constructor() {}

  setAccount(account: Account) {
    this.account.next(account);
  }

}
