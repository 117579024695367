import { Component, OnInit } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { WishlistService } from '../../services/wishlist.service';
import { Router, RouterModule } from '@angular/router';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterModule
  ],
  providers: [
    
  ],
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit{
  cartItemsCount: number = 0;
  wishlistCount: number = 0;
  constructor(
    private router: Router,
    private cartServ: CartService, private wishlistServ: WishlistService ) {}
  ngOnInit(): void {
    this.cartServ.cart$.subscribe(
      items => {
        this.cartItemsCount = 0;
        for(let i = 0; i < items.length; i++) {
          const item = items[i];
          this.cartItemsCount += item.quantity;
        }
      }
    );

    this.wishlistServ.wishlist$.subscribe(
      wishlist => {
        this.wishlistCount = wishlist.length;
      }
    );
  }

  search() {
    this.router.navigate(['/shop']);
  }
}
