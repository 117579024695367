<body>
    <app-header></app-header>
    <app-navbar></app-navbar>

    <!-- breadcrumb -->
    <div class="container py-4 flex items-center gap-3">
        <a href="../index.html" class="text-primary text-base">
            <i class="fa-solid fa-house"></i>
        </a>
        <span class="text-sm text-gray-400">
            <i class="fa-solid fa-chevron-right"></i>
        </span>
        <p class="text-gray-600 font-medium">Order Detail</p>
    </div>
    <!-- ./breadcrumb -->

    <!-- wrapper -->
    <div class="container grid grid-cols-12 items-start gap-6 pt-4 pb-16">

            <!-- sidebar -->
            <div class="col-span-3">
                <app-sidebar [page]="'order'"></app-sidebar>
            </div>
            <!-- ./sidebar -->
    
            <!-- info -->
            @if(order) {
                <div class="col-span-9 shadow rounded px-6 pt-5 pb-7">
                    <h4 class="text-lg font-medium capitalize mb-4">
                        Order Detail
                    </h4>
    
                    <dl class="text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                        <div class="flex p-3 justify-between">
                            <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">code</dt>
                            <dd class="text-lg font-semibold">{{code}}</dd>
                        </div>

                        <div class="flex p-3 justify-between">
                          <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">created at</dt>
                          <dd class="text-lg font-semibold">{{order.created_at | date}}</dd>
                        </div>

                        <div class="flex p-3 justify-between">
                          <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">total amount</dt>
                          <dd class="text-lg font-semibold">{{order.fiat.symbol}}{{order.total_amount}}</dd>
                        </div>

                        <div class="flex p-3 justify-between">
                          <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">payment status</dt>
                          <dd class="text-lg font-semibold"><app-payment-status [status]="order.payment_status"></app-payment-status></dd>
                        </div>
                    </dl>


                    @for (item of order.items; track item) {
                      <div class="flex items-center justify-between border gap-6 p-4 border-gray-200 rounded">
                          <div class="w-28">
                              <img src="{{item.product_image | image}}" alt="product 6" class="w-full">
                          </div>
                          <div class="w-1/3">
                              <h2 class="text-gray-800 text-xl font-medium uppercase">{{item.product_name}}</h2>
                          </div>
                          <div class="text-primary text-lg font-semibold">${{item.amount}} x {{item.quantity}}</div>
                          <a [routerLink] = "['/product/' + item.product_slug]"
                              class="hover:bg-transparent hover:text-primary px-6 py-2 text-center text-sm text-white bg-primary border border-primary rounded  transition uppercase font-roboto font-medium"
                              >View</a>
                      </div>
                      }                    

    
                </div>
            }

            <!-- ./info -->
    
    </div>

    <!-- ./wrapper -->
     
    <app-footer></app-footer>
    <app-copyright></app-copyright>

</body>