import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from "../components/header/header.component";
import { NavbarComponent } from "../components/navbar/navbar.component";
import { FooterComponent } from '../components/footer/footer.component';
import { CopyrightComponent } from '../components/copyright/copyright.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { ApiService } from '../services/api.service';
import { AccountService } from '../services/account.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-password',
  standalone: true,
  imports: [
    HeaderComponent, 
    NavbarComponent,
    FooterComponent,
    CopyrightComponent,
    SidebarComponent,
    FormsModule
  ],
  templateUrl: './password.component.html',
  styleUrl: './password.component.scss'
})
export class PasswordComponent {
  
  oldPassword: string = '';
  password: string = '';
  confirmPassword: string = '';
  code: number = -1;

  constructor(private apiServ: ApiService, private accountServ: AccountService) {}
  update() {
    this.apiServ.updatePassword(this.oldPassword, this.password)
    .subscribe({
      next: (account: any) => {
        this.code = 0;

      }
    });
  }
}
