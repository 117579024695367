import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {
  categories: any;
  constructor(private apiServ: ApiService) {}
  ngOnInit(): void {
    this.apiServ.getCategories().subscribe({
      next: (categories) => {
        this.categories = categories;
      }
    });
  }
}
