<div class="bg-gray-800 py-4">
    <div class="container flex items-center justify-between">
        <p class="text-white">&copy; Biamm - All Right Reserved</p>
        <div class="flex">
            @for (item of cryptos; track item) {
                <div class="ml-2">
                    <img class="w-8 " src="{{item.image | image}}">
                </div>
                
            }
            <!--
            <img src="assets/images/methods.png" alt="methods" class="h-5">
            -->
        </div>
    </div>
</div>